body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main{
  display: grid;
  grid-template-rows: 100px auto 100px;
  align-items: center;
  place-items: center;
  height: 100vh;
  width: 100vw;
}

nav {
  width: 100%;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  height: 100px;
  display: grid;
  place-items: center;
}

.catalog-grid {
  padding: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-content: center;
  gap: 3rem;
 
}

.item-image {
  max-width: 330px;
  max-height: 277px;

  
}

.item-image::before {
  max-width: 330px;
  max-height: 277px;
  z-index:99;
  
}
.item-image:hover {
  cursor: pointer;
  
  z-index: 0;

}
h3, p {
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: 300;
}

.item {
  position: relative;
  max-width: 330px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #154B7066;
  border-radius: 10px;
  opacity: 1;

  max-width: 100%;
 
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 999;
}

@media (max-width: 768px) {
  .catalog-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}